<template>
  <div class="wrap-box">
    <van-search v-model="value" show-action label="" placeholder="请输入搜索关键词" @search="onSearch">
      <template #action>
        <div @click="onSearch">查询</div>
      </template>
    </van-search>
    <div v-if="cardList" class="box">
      <p class="item" v-for="(item, index) in cardList" @click="onSearchs(item.card)" :key="index">
        {{ item.card }}
      </p>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  //
  data() {
    return {
      activeNames: ['1'],
      value: '',
      typeList: ['事故维修', '正常维修'],
      statusList: ['未处理', '进厂', '定损', '备件', '维修中', '洗车', '待提车', '离厂'],
      nowData: '',
      id: '',
      list: '',
      active: 0,
      hasData: false,
      cardList: '',
    }
  },
  async mounted() {
    const list = localStorage.getItem('card')
    if (list) {
      this.cardList = JSON.parse(list)
    }
  },
  methods: {
    onSearch() {
      if (this.value) {
        this.$router.replace({
          path: '/search',
          query: {
            card: this.value,
          },
        })
      }
    },
    onSearchs(e) {
      this.$router.replace({
        path: '/search',
        query: {
          card: e,
        },
      })
    },
  },
}
</script>
<style scoped>
.box {
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
  padding: 10px 20px;
}
.item {
  font-size: 14px;
  padding: 5px;
  background: #ddd;
  color: #333;
  margin: 4px 5px;
  border-radius: 4px;
}
</style>
