<template>
  <div class="wrap-box">
    <van-search v-model="value" show-action label="" placeholder="请输入搜索关键词" @search="onSearch">
      <template #action>
        <div @click="onSearch">查询</div>
      </template>
    </van-search>
    <van-collapse v-model="activeNames" v-if="hasData">
      <van-collapse-item name="1">
        <template #title>
          <div class="title-box">
            <div class="title-dec">
              <p class="p">事故维修</p>
              <p class="time" style="height: 18px;">{{ list[active].time }}</p>
            </div>
            <p class="icon-box">{{ list[active].text }}</p>
          </div>
        </template>
        <div class="content">
          <van-steps direction="vertical" :active="active" active-icon="checked" active-color="#2E82FE">
            <van-step v-for="(item, index) in list" :key="index">
              <h3>{{ item.text }}</h3>
              <p>{{ item.time }}</p>
            </van-step>
          </van-steps>
        </div>
      </van-collapse-item>
    </van-collapse>
    <div v-if="!hasData" class="no-css">
      暂无数据
    </div>
    <p class="history" @click="handleHistory">
      维修历史记录
    </p>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  //
  data() {
    return {
      activeNames: ['1'],
      value: '',
      typeList: ['事故维修', '正常维修'],
      statusList: ['未处理', '进厂', '定损', '备件', '维修中', '洗车', '待提车', '离厂'],
      nowData: '',
      id: '',
      list: '',
      active: 0,
      hasData: false,
    }
  },
  async mounted() {
    console.log(this.$route.query)
    // const card = localStorage.getItem('card')
    const { card = '' } = this.$route.query
    if (card == '') {
      this.$router.replace('/search-list')
      return
    }
    if (card) {
      this.value = card
      this.onSearch(card, 0)
    }

    // this.onSearch('鲁E7366N')
  },
  methods: {
    async onSearch(e, code = 1) {
      if (code == 1) {
        const list = localStorage.getItem('card')
        if (list) {
          let arr = JSON.parse(list).filter((item) => item.card != this.value)
          arr.push({ card: this.value })
          localStorage.setItem('card', JSON.stringify(arr))
        } else {
          let arr = [{ card: this.value }]
          localStorage.setItem('card', JSON.stringify(arr))
        }
      }

      const result = await axios.get(api + '/api/fs/repair_work_order/mobile/list', {
        params: {
          pageNum: 1,
          pageSize: 10,
          licensePlate: this.value,
        },
      })
      if (result.data.rows.length > 0) {
        this.hasData = true
      } else {
        this.hasData = false
        this.active = 0
      }

      try {
        this.nowData = result.data.rows[0]
        this.id = this.nowData.id
      } catch (e) {
        console.log(e)
      }
      console.log(this.nowData)

      const listArr = await axios.get(api + `/api/fs/repair_work_order/state/${this.nowData.id}`)
      this.list = listArr.data.data
      for (let i = 1; i < this.list.length + 1; i++) {
        if (this.list[i - 1].code == this.nowData.state) {
          this.active = i - 1
        }
      }
      console.log(this.value)
      console.log(this.active)
    },
    handleHistory() {
      this.$router.push({
        path: '/searchhistory',
        query: {
          card: this.value,
        },
      })
    },
  },
}
</script>
<style scoped>
.title-dec {
  font-size: 12px;
}
.title-dec .p {
  font-size: 14px;
  font-weight: bold;
}
.time {
}
.title-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.icon-box {
  background: #2e82fe;
  color: #fff;
  padding: 2px 8px;
  font-size: 12px;
  border-radius: 3px;
  margin-right: 15px;
  height: 30px;
  line-height: 30px;
}
.wrap-box {
  width: 100vw;
  min-height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;
}

.history {
  position: absolute;
  bottom: 40px;
  left: 0;
  width: 100vw;
  font-size: 14px;
  text-align: center;
  color: #2e82fe;
}
.step-items {
  display: flex;
  justify-content: space-between;
  color: #969799;
}
.no-css {
  width: 100vw;
  height: 40vh;
  text-align: center;
  font-size: 18px;
  padding-top: 30px;
  color: #999;
}
/* // 2E82FE */
</style>
